<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <section
    class="AiHeroImaged"
    :class="{
      'AiHeroImaged-homePage': homePage,
      'AiHeroImaged-hotelPage': hotelPage,
    }">
    <ai-image
      v-if="heroImage"
      class="AiHeroImaged-background"
      :src="heroImage.src"
      :src-set="heroImage.srcSet as string"
      :alt="heroImage.alt as string"
      fetch-priority="high" />

    <article class="Layout">
      <div
        class="AiHeroImaged-inner Layout-section"
        :class="{ 'AiHeroImaged-inner--full': innerFullHeight }">
        <grid-item sm="4" md="12" class="AiHeroImaged-inner-header">
          <slot name="header" />
          <ai-breadcrumb
            v-if="breadcrumbItems && breadcrumbItems.length"
            class="AiHeroImaged-inner-breadcrumb"
            :breadcrumb-items="breadcrumbItems"
            :variant="variant" />
        </grid-item>
        <grid-item sm="4" md="12" class="AiHeroImaged-inner-body">
          <ai-logo
            v-if="hotelBrandCode"
            class="AiHeroImaged-inner-logo"
            :variant="variant"
            :brand="hotelBrandCode"
            :aria-label="
              $t('accessibility.description.hotelHeroLogo', {
                hotelName: heroTitle,
              })
            " />
          <ai-typo
            v-if="heroTitle"
            class="AiHeroImaged-inner-name"
            :class="[` AiHeroImaged-inner-name--${variant}`]"
            as="h1"
            variant="heading-01">
            <ai-typo as="span" class="AiTypo--color-white" v-html="heroTitle" />
            <ai-typo v-if="heroTitle2" as="span" class="AiTypo--color-white">
              <br />
              {{ heroTitle2 }}
            </ai-typo>
          </ai-typo>
          <ai-typo
            v-if="heroDescription"
            class="AiHeroImaged-inner-description"
            :class="`AiHeroImaged-inner-name--${variant}`"
            as="p"
            variant="heading-04">
            {{ heroDescription }}
          </ai-typo>
          <ai-star
            v-if="numberOfStar"
            class="AiHeroImaged-inner-star"
            :variant="variant"
            :number-of-star="numberOfStar" />
          <ai-location
            v-if="cityLabel && countryLabel"
            class="AiHeroImaged-inner-location"
            :city="cityLabel ?? ''"
            mobile-icon
            :variant="variant"
            :country="countryLabel" />
          <grid-item
            v-if="experienceCategories && experienceCategories.length"
            :aria-label="$t('accessibility.description.hotelHeroExperiences')"
            sm="8"
            md="12"
            class="AiHeroImaged-inner-pictograms"
            role="list">
            <grid-item
              v-for="(categorie, index) of experienceCategories"
              :key="`AiExperienceCategory-item-${index}`"
              role="listitem"
              sm="1"
              md="2"
              l="1">
              <ai-pictograms
                :variant="variant"
                icon-position="end"
                :size="pictogramsSize"
                :title="categorie.title.toUpperCase()"
                :url="categorie.url"
                typo-color="white" />
            </grid-item>
          </grid-item>
        </grid-item>

        <grid-item
          sm="4"
          md="12"
          class="AiHeroImaged-inner-footer"
          :class="{ 'AiHeroImaged-noVideo': !displayVideo }">
          <ai-media-button
            v-if="displayVideo"
            class="AiHero-mediaButton"
            :button-label="$t('hotel.hero.mediaButton')"
            :variant="variant" />
          <ai-rating
            v-if="rating && reviews"
            :variant="variant"
            :rating="rating"
            :size="18"
            type="star"
            :reviews="reviews"
            class="AiHeroImaged-inner-rating"
            :hotel-slug="hotelSlug" />
        </grid-item>

        <grid-item v-if="$slots.footer" sm="4" md="12" class="AiHero-footer">
          <slot name="footer" />
        </grid-item>
      </div>
    </article>
  </section>
</template>

<script setup lang="ts">
import type { BrandCode } from '../../atoms/AiLogo/AiLogo.vue';
import type { BreadcrumbItem } from '../AiBreadcrumb/AiBreadcrumb.vue';

import type { AiPicture } from '~~/domains/graphql';

export type ExperienceCategories = {
  title: string;
  url: string;
};

export interface AiHeroProps {
  breadcrumbItems?: BreadcrumbItem[] | null;
  cityLabel?: string;
  countryLabel?: string;
  heroDescription?: string;
  heroTitle?: string;
  heroTitle2?: string;
  displayVideo?: boolean;
  hotelBrandCode?: BrandCode | string | null;
  numberOfStar?: number | null;
  experienceCategories?: ExperienceCategories[] | null;
  rating?: number | null;
  reviews?: number | null;
  variant?: 'positive' | 'inverse';
  heroImage?: AiPicture | null;
  homePage?: boolean;
  hotelPage?: boolean;
  innerFullHeight?: boolean;
  hotelSlug?: string;
  partialHeight?: string;
}
withDefaults(defineProps<AiHeroProps>(), {
  breadcrumbItems: null,
  cityLabel: '',
  countryLabel: '',
  experienceCategories: null,
  heroDescription: '',
  heroImage: null,
  heroTitle: '',
  heroTitle2: '',
  homePage: false,
  hotelBrandCode: null,
  hotelPage: false,
  numberOfStar: null,
  rating: null,
  reviews: null,
  variant: 'positive',
  hotelSlug: '',
  partialHeight: '100vh',
});

const isSmallDevice = useMatchMediaQuery('tablet');

const pictogramsSize = computed(() => (isSmallDevice.value ? 40 : 32));
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/functions' as func;

.AiHeroImaged-homePage {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 13.74%,
    rgba(0, 0, 0, 0.4) 51.43%,
    rgba(0, 0, 0, 0.4) 79%
  );
}
.AiHeroImaged-hotelPage {
  min-height: v-bind(partialHeight);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 17.39%,
    rgba(0, 0, 0, 0.4) 65.1%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
.AiHeroImaged-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: -1;
}

.AiHeroImaged {
  position: relative;
  min-height: v-bind(partialHeight);
  background-repeat: no-repeat;
  background-size: cover;

  &-inner {
    display: flex;
    min-height: v-bind(partialHeight);
    overflow: hidden;
    gap: 50px;

    @media (mq.$from-medium) {
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    &-breadcrumb {
      display: none;
      @media (mq.$from-medium) {
        display: flex;
      }
    }

    &-logo {
      margin-bottom: constants.$margin-03;
    }

    &-star {
      margin-bottom: constants.$margin-02;
    }

    &-name {
      text-align: center;

      margin-bottom: constants.$margin-00;

      & > * {
        font-size: clamp(func.calcRem(32), 6vh, func.calcRem(48));
      }

      @media (mq.$from-large) {
        margin-bottom: constants.$margin-01;

        & > * {
          font-size: inherit;
        }
      }

      &--inverse {
        color: colors.$white;
      }
    }

    &-location {
      margin-bottom: constants.$margin-03;
      align-items: center;
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: constants.$margin-04;
      @media (mq.$from-medium) {
        margin-bottom: constants.$margin-05;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .AiHero-mediaButton {
    margin-bottom: constants.$margin-02;
    text-transform: uppercase;
    @media (mq.$from-medium) {
      margin-bottom: 0;
    }
  }
}

.AiHeroImaged-inner--full {
  height: 100%;
}
.AiHeroImaged-inner-description {
  text-align: center;
  font-size: clamp(func.calcRem(18), 2.9vh, func.calcRem(24));

  .AiHeroImaged-homePage & {
    color: colors.$white;
  }
}
.AiHeroImaged-noVideo {
  justify-content: flex-end;
}
.AiHeroImaged-inner-name--noMarginBottom {
  margin-bottom: 0;
}

.AiHeroImaged-inner-pictograms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: constants.$margin-02;

  @media (mq.$from-medium) {
    margin-bottom: 0;
  }
}
</style>
